import {
  cardTitle,
  container,
  description,
  mlAuto,
  mrAuto,
  section,
  textCenter,
  title,
} from "assets/jss/material-kit-pro-react.jsx"

const admissionsPurchaseStyle = (theme) => ({
  cardGeneralPrice: {
    ...cardTitle,
    fontSize: "1.5rem",
  },
  cardHeader: {
    ...cardTitle,
    fontWeight: "600 !important",
    fontSize: "1.5rem",
    marginBottom: "20px",
    marginTop: "5px",
  },
  cardInfo: {
    ...cardTitle,
  },
  cardVipPrice: {
    ...cardTitle,
    fontSize: "1.25rem",
  },
  container: {
    ...container,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "650px",
    },
  },
  customIcon: {
    height: "55px",
    width: "auto",
    margin: "10px",
  },
  description,
  externalLink: {
    ...description,
    color: "#777",
    "&:hover": {
      color: "#6d91e4",
    },
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important",
  },
  section: {
    ...section,
    padding: "40px 0",
    position: "relative",
    backgroundSize: "initial",
    "& $container": {
      position: "relative",
      zIndex: "2",
    },
    "& $title": {
      marginBottom: "10px",
    },
  },
  smallDescription: {
    color: "#888",
    fontSize: ".85rem",
    fontWeight: "400",
  },
  ticketButton: {
    margin: "1rem",
    lineHeight: "18px",
    "& svg": {
      margin: "0 0 0 5px",
    },
  },
  ticketCard: {
    border: "5px solid #e4240eaa",
    boxShadow: "none",
    transition: "all .2s ease-in-out",
    "&:hover": {
      boxShadow: "0px 35px 20px -20px rgba(0, 0, 0, 0.3)",
    },
  },
  ticketDivider: {
    width: "10%",
    border: "3px solid #e4240eaa",
  },
  ticketFooter: {
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  ticketHoleLeft: {
    position: "absolute",
    width: "25px",
    height: "50px",
    top: "50%",
    left: "-5px",
    transform: "translateY(-50%)",
    background: "#fff",
    border: "5px solid #e4240eaa",
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
    borderLeft: "0px",
    zIndex: "1",
  },
  ticketHoleRight: {
    position: "absolute",
    width: "25px",
    height: "50px",
    top: "50%",
    right: "-5px",
    transform: "translateY(-50%)",
    background: "#fff",
    border: "5px solid #e4240eaa",
    borderTopLeftRadius: "50px",
    borderBottomLeftRadius: "50px",
    borderRight: "0px",
    zIndex: "1",
  },
  ticketItem: {
    ...mlAuto,
    ...mrAuto,
    ...textCenter,
  },
  ticketIcon1: {
    fontSize: "50px",
    color: "#fad4d1",
  },
  ticketIcon2: {
    fontSize: "100px",
    color: "#ef7e77",
  },
  ticketsAlign: {
    position: "absolute",
    zIndex: "-1",
    left: "-2.5%",
    width: "50%",
    top: "10%",
    opacity: "40%",
  },
  title,
})

export default admissionsPurchaseStyle
